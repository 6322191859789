import React from 'react'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import './style.scss'

class Admissions extends React.Component{

    render () {
        
      function importAll(images) {
        return images.keys().map(images);
      }
      
      const images = importAll(require.context('static/UniLogo/', false, /\.(png|jpe?g|svg)$/));
      const imageArray = images.map(function(image) {
        return (<div className="p-custom col-4 col-lg-2 m-auto text-center"> 
            <img key={image} src={image} />
        </div> );
      }); 

    return (

        <section id="admissions">
            <div className="container">
                <h2> <FormattedMessage id="our_success" /> </h2>
                <div className="row">
                        {imageArray}
                    </div>
                    </div>
        </section>
    )
}
}

export default Admissions

