import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import Admissons from 'components/Admissions'
import SEO from 'components/SEO'
import { injectIntl, FormattedMessage,FormattedHTMLMessage } from "gatsby-plugin-intl"



const AboutPage = ({ intl }) => {

  return (

  <Layout>

          <SEO
            site={siteMetadata}
            title="About Us"
            description="We value education, personal and professional development from experiences abroad. Challenge yourself beyond academics and discover your passions with us."
            
           />

    <section id="thumbnail">
      <div className="center-cropped">
          <img src={require('../images/about.jpeg')} alt="about.jpeg" />

          <div className="centered">
        <p id="thumbnailText"><em><FormattedMessage id="about_we_understand" /></em></p>
      </div>
      </div>
    </section>


    <section id="ourBeliefs" className="centeredText">
      <div className="container m-r-auto m-l-auto">
        <h1><FormattedMessage id="about_our_beliefs" /></h1>
        <p><FormattedHTMLMessage id="about_p1" /></p>
        <p><FormattedHTMLMessage id="about_p2" /></p>
        <p><FormattedHTMLMessage id="about_p3" /></p>
      </div>
    </section>

    <section id="ourMission">   
      <div className="container">
        
        <h2><FormattedMessage id="about_our_mission" /></h2>

        <div className="row">
            <div className="col-lg-6 col-12">
              <h3><FormattedMessage id="about_discover" /> </h3>
              <p><FormattedHTMLMessage id="about_discover_p" /></p>
            </div>
            <div className="col-lg-6 col-12">
              <h3><FormattedMessage id="about_develop" /></h3>
              <p><FormattedHTMLMessage id="about_develop_p" />
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <h3><FormattedMessage id="about_empower" /></h3>
              <p><FormattedHTMLMessage id="about_empower_p" /></p>
            </div>
            <div className="col-lg-6 col-12">
              <h3><FormattedMessage id="about_support" /></h3>
              <p><FormattedHTMLMessage id="about_support_p" /></p>
            </div>
          </div>  
          <Admissons />
        </div>   
    </section>
    
</Layout>
  )
}

export default injectIntl(AboutPage)